
function load_in_modal(modal_id, url, options, modal_options) {
    if (typeof options === 'undefined') {
        /* Example Options.
        * {title: "Add Image"}
        */
        options = {};
    }
    if (typeof modal_options === 'undefined') {
        modal_options = {}
    }

    $('#' + modal_id + ' .modal-body').html('<h3>Loading...</h3>');
    /* Remember to set an id in your modal's body */
    $.web2py.component(url, $('#' + modal_id + ' .modal-body').attr('id'));

    $('#' + modal_id + ' .modal-dialog').addClass('modal-lg')
    if (typeof options.title !== undefined) {
        $('#' + modal_id + '  .modal-title').text(options.title);
        $('#' + modal_id + '  .modal-header').show();
    } else {
        $('#' + modal_id + '  .modal-header').hide();
    }
    modal = new bootstrap.Modal(document.getElementById(modal_id), modal_options);
    modal.show();
    $('[data-bs-dismiss="'+ modal_id +'"]').click(function() {
        modal.hide();
    });
    return modal;
}
window.load_in_modal = load_in_modal

function remove_empty(obj) { /* remove null or '' value keys*/
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => (v !== null) && (v !== '')));
}

window.remove_empty = remove_empty



function filesize(arg) {
    /**
     * filesize
     *
     * @copyright 2020 Jason Mulligan <jason.mulligan@avoidwork.com>
     * @license BSD-3-Clause
     * @version 6.1.0
     */
    var b = /^(b|B)$/,
        symbol = {
      iec: {
        bits: ["b", "Kib", "Mib", "Gib", "Tib", "Pib", "Eib", "Zib", "Yib"],
        bytes: ["B", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
      },
      jedec: {
        bits: ["b", "Kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"],
        bytes: ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      }
    },
        fullform = {
      iec: ["", "kibi", "mebi", "gibi", "tebi", "pebi", "exbi", "zebi", "yobi"],
      jedec: ["", "kilo", "mega", "giga", "tera", "peta", "exa", "zetta", "yotta"]
    };
    var descriptor = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var result = [],
        val = 0,
        e = void 0,
        base = void 0,
        bits = void 0,
        ceil = void 0,
        full = void 0,
        fullforms = void 0,
        locale = void 0,
        localeOptions = void 0,
        neg = void 0,
        num = void 0,
        output = void 0,
        round = void 0,
        unix = void 0,
        separator = void 0,
        spacer = void 0,
        standard = void 0,
        symbols = void 0;

    if (isNaN(arg)) {
        throw new TypeError("Invalid number");
    }

    bits = descriptor.bits === true;
    unix = descriptor.unix === true;
    base = descriptor.base || 2;
    round = descriptor.round !== void 0 ? descriptor.round : unix ? 1 : 2;
    locale = descriptor.locale !== void 0 ? descriptor.locale : "";
    localeOptions = descriptor.localeOptions || {};
    separator = descriptor.separator !== void 0 ? descriptor.separator : "";
    spacer = descriptor.spacer !== void 0 ? descriptor.spacer : unix ? "" : " ";
    symbols = descriptor.symbols || {};
    standard = base === 2 ? descriptor.standard || "jedec" : "jedec";
    output = descriptor.output || "string";
    full = descriptor.fullform === true;
    fullforms = descriptor.fullforms instanceof Array ? descriptor.fullforms : [];
    e = descriptor.exponent !== void 0 ? descriptor.exponent : -1;
    num = Number(arg);
    neg = num < 0;
    ceil = base > 2 ? 1000 : 1024; // Flipping a negative number to determine the size

    if (neg) {
        num = -num;
    } // Determining the exponent

    if (e === -1 || isNaN(e)) {
        e = Math.floor(Math.log(num) / Math.log(ceil));
        if (e < 0) {
            e = 0;
        }
    } // Exceeding supported length, time to reduce & multiply

    if (e > 8) {
        e = 8;
    }

    if (output === "exponent") {
        return e;
    } // Zero is now a special case because bytes divide by 1

    if (num === 0) {
        result[0] = 0;
        result[1] = unix ? "" : symbol[standard][bits ? "bits" : "bytes"][e];
    } else {
        val = num / (base === 2 ? Math.pow(2, e * 10) : Math.pow(1000, e));
        if (bits) {
        val = val * 8;
            if (val >= ceil && e < 8) {
                val = val / ceil;
                e++;
            }
        }

        result[0] = Number(val.toFixed(e > 0 ? round : 0));

        if (result[0] === ceil && e < 8 && descriptor.exponent === void 0) {
            result[0] = 1;
            e++;
        }

        result[1] = base === 10 && e === 1 ? bits ? "kb" : "kB" : symbol[standard][bits ? "bits" : "bytes"][e];

        if (unix) {
            result[1] = standard === "jedec" ? result[1].charAt(0) : e > 0 ? result[1].replace(/B$/, "") : result[1];
            if (b.test(result[1])) {
                result[0] = Math.floor(result[0]);
                result[1] = "";
            }
        }
    } // Decorating a 'diff'

    if (neg) {
        result[0] = -result[0];
    } // Applying custom symbol

    result[1] = symbols[result[1]] || result[1];

    if (locale === true) {
        result[0] = result[0].toLocaleString();
    } else if (locale.length > 0) {
        result[0] = result[0].toLocaleString(locale, localeOptions);
    } else if (separator.length > 0) {
        result[0] = result[0].toString().replace(".", separator);
    } // Returning Array, Object, or String (default)


    if (output === "array") {
        return result;
    }

    if (full) {
        result[1] = fullforms[e] ? fullforms[e] : fullform[standard][e] + (bits ? "bit" : "byte") + (result[0] === 1 ? "" : "s");
    }

    if (output === "object") {
        return {
            value: result[0],
            symbol: result[1],
            exponent: e
        };
    };
    return result.join(spacer);
} 
window.filesize = filesize;
/** END FILESIZE **/

function text_only_paste(e) {
    e.preventDefault();
    var text = e.clipboardData.getData('text/plain');
    console.log(Ractive.getContext(e.originalTarget).resolve());
    document.execCommand('insertText', false, text);
}

window.text_only_paste = text_only_paste;


function icon_for_mime_type(mime) {
    icon = 'fa-file-o'
    if (mime.startsWith('image/')) {
        icon = 'fa-file-image-o';
    } else if(mime.startsWith('video/')) {
        icon = 'fa-file-video-o';
    } else if(mime.startsWith('audio/')) {
        icon = 'fa-file-audio-o';
    } else if(mime.startsWith('text/')) {
        icon = 'fa-file-text-o';
    } 
    switch(mime) {
        case 'application/pdf':
            icon = 'fa-file-pdf-o';
        break;
        case 'application/msword':
        case 'application/x-abiword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            icon = 'fa-file-word-o'
        break;
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            icon = 'fa-file-excel-o'
        break;
        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            icon = 'fa-file-powerpoint-o'
        break;
        case 'application/gzip':
        case 'application/x-freearc':
        case 'application/x-tar':
        case 'application/zip':
        case 'application/x-7z-compressed':
        case 'application/vnd.rar':
            icon = 'fa-file-archive-o'
        break;
    }

    return icon;
}
window.icon_for_mime_type = icon_for_mime_type;

function S4() {
   return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
}
function guid() {
   return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

Ractive.prototype.split_index = function(keypath) {
    var result = {}
    var last_dot = keypath.lastIndexOf('.');
    result.container = keypath.substr(0, last_dot);
    result.index = parseInt(keypath.substring(last_dot + 1));
    return result;
}

Ractive.prototype.pop_array_path = function(keypath) {
    const popped = this.get(keypath);
    const last_dot = keypath.lastIndexOf('.');
    const container = keypath.substr(0, last_dot);
    const index = keypath.substring(last_dot + 1);
    this.splice(container, index, 1);
    return popped;
}

Ractive.decorators.selectized = function(node) {
    let handle = null;
    handle = $(node).selectize();
    let selectizer = handle[0].selectize;
    // let value = select.options[select.selectedIndex].value;
    // selectizer.setValue(value);
    // console.log(value);
    selectizer.on('change', function() {
        node.dispatchEvent(new Event('change'));
    });
    return {
        teardown: function() {
            if (selectizer !== null) {
                selectizer.clear();
                selectizer.clearOptions();
                selectizer.destroy();
                selectizer = null;
            }
        }
    };
}

Ractive.decorators.suggesting = function(node, options, kp, context) {
    /* Tries to give suggestions from a list of options in a text input */
    let handle = $(node).selectize({
        create: true,
        maxItems: 1,
        render: {
            option_create: function(data, escape) {
              return '<div class="create">' + escape(data.input) + '</div>';
            }
        }
    });
    let selectizer = handle[0].selectize;
    selectizer.clear(true);
    for (i = 0; i < options.length; i++) {
      selectizer.addOption({
        value: options[i],
        text: options[i],
      });
    }
    if (context) {
        selectizer.setValue(context.get(kp));
        selectizer.on('change', function() {
            context.set(kp, selectizer.getValue());
        });
    }
    return {
        teardown: function() {
            if (selectizer !== null) {
                if (context) {
                    selectizer.off('change');
                }
                selectizer.clear();
                selectizer.clearOptions();
                selectizer.destroy();
            }
        }
    };
}

Ractive.components.loading = Ractive.extend({
    isolated: true,
    template: `
    <div class="text-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div> 
    `
})

Ractive.components.noresultsicon = Ractive.extend({
    isolated: true,
    template: `
    <div class="d-inline-block position-relative"><i class="bi bi-search"></i><i class="bi bi-x" style="position: absolute;top:-0.1rem;left:-0.09rem;"></i></div>
    `
})


Ractive.components.namepicker = Ractive.extend({
    isolated: true,
    template: `<select class="form-control"></select>`,
    data: function(){ return { 
        value: null,
        placeholder: '',
        search_url: '',
        options: [],
        result_key: 'items',
        maxitems: 1
    };},
    delimiters: [ '[[', ']]' ],
    tripleDelimiters: [ '[[[', ']]]' ],
    onrender: function() {
        let self = this;

        let el = self.find('select');
        let placeholder = self.get('placeholder');

        var handle = $(el).selectize({
            valueField: 'id',
            labelField: 'name',
            searchField: 'name',
            placeholder: placeholder,
            items: self.get('value'),
            options: self.get('options'),
            maxItems: self.get('maxitems'),
            render: {
                item: function(data, escape) {
                    return '<span data-value="' + data.id + '" class="mr-1 pl-1 pr-1 pb-1 bg-light">' + escape(data.name) + '</span>';
                }
            },
            onItemAdd: function(value, item) {
                let val = self.get('selectize').getValue();
                if (Array.isArray(val)) {
                    val = val.map(v => parseInt(v));
                } else {
                    val = parseInt(val);
                }
                self.set('value', val);
            },
            onItemRemove: function(value, item) {
                let val = self.get('selectize').getValue();
                self.set('value', val);
            },
            load: function(query, callback) {
                if (!query.length) return callback();
                $.ajax({
                    url: self.get('search_url'),
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        q: query,
                        page_limit: 10,
                    },
                    error: function() {
                        callback();
                    },
                    success: function(res) {
                        callback(res[self.get('result_key')]);
                    }
                });
            }
        });
        self.set('selectize', handle[0].selectize);
    },
    onunrender: function() {
        const selectize = this.get('selectize');
        selectize.clear(true);
        selectize.destroy();
    },
});

Ractive.components.datepicker = Ractive.extend({
    isolated: true,
    template: `
        [[#if minimizable]]
            <span class="datepicker-wrapper [[class]]" class-minimized="minimized" class-disabled="disabled" [[#unless disabled]]on-click="unminimize"[[/unless]]>
                [[#if minimized]]
                    [[#unless !value && disabled]]<i class="fa fa-calendar"></i> [[/unless]][[value]]
                [[else]]
                    <span class-text-primary="!minimized" on-click="@this.toggle('minimized')"><i class="fa fa-chevron-up"></i><i class="fa fa-calendar"></i></span>
                [[/if]]
                <input [[#if disabled]]disabled[[/if]] autocomplete="off" value="[[./value]]" placeholder="[[placeholder]]" class="datepicker form-control mt-1">
            </span>
        [[else]]
            <input [[#if disabled]]disabled[[/if]] autocomplete="off" value="[[./value]]" placeholder="[[placeholder]]" class="datepicker form-control mt-1">
        [[/if]]
    `,
    css: `
        .datepicker-wrapper.minimized:not(.disabled) {
            cursor: pointer !important;
        }
        .datepicker-wrapper {
            position: relative;
        }
        .datepicker-wrapper.minimized .datepicker {
            display: none;
        }
        .todolist input.datepicker {
            margin-top: 0!important;
        }
    `,
    data: function(){ return { 
        value: '',
        class: '',
        disabled: false,
        placeholder: '',
        minimizable: false,
        minimized: false
    };},
    delimiters: [ '[[', ']]' ],
    tripleDelimiters: [ '[[[', ']]]' ],
    onrender: function() {
        let self = this;
        let el = self.find('input');
        $(el).datepicker({
            format: w2p_ajax_date_format.replace('%Y', 'yyyy').replace('%m', 'mm').replace('%d', 'dd')
        });

        self.observe('minimized', function(nv, ov, kp) {
            if (nv) {
                self.on('unminimize', function(context) {
                    context.original.stopPropagation();
                    context.original.preventDefault();
                    self.set('minimized', false);
                    return false;
                });
            } else {
                self.off('unminimize');
            }
        });
    },
    onunrender: function() {
    }
});


if (typeof window.CKEDITOR !== 'undefined') {
    CKEDITOR.disableAutoInline = true;
    Ractive.components.ckeditor = Ractive.extend({
        isolated: true,
        template: `
            <textarea>[[body]]</textarea>
        `,
        css: `
        `,
        data: function(){ 
            return {body: '', editor: null, upload_url: null, browse_url: null};
        },
        delimiters: [ '[[', ']]' ],
        tripleDelimiters: [ '[[[', ']]]' ],
        onrender: function() {
            let self = this;
            let el = self.find('textarea');
            
            function make_config() {
                const config = {}
                config.removePlugins = 'div,templates,language,preview,about,flash,forms,find,print,pagebreak,scayt,exportpdf,newpage,save,bidi,sourcearea';
                config.extraPlugins = 'sourcedialog,iframe';
                config.linkDefaultProtocol = 'https://';
                config.toolbarGroups = [
                    { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
                    { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
                    { name: 'forms', groups: [ 'forms' ] },
                    { name: 'styles', groups: [ 'styles'] },
                    { name: 'tools', groups: [ 'tools' ] },
                    { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
                    '/',
                    { name: 'basicstyles', groups: ['basicstyles', 'cleanup', 'Font', 'FontSize' ] },
                    { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
                    { name: 'links', groups: [ 'links' ] },
                    { name: 'insert', groups: [ 'insert' ] },
                    { name: 'colors', groups: [ 'colors' ] },
                    { name: 'others', groups: [ 'others' ] },
                    { name: 'about', groups: [ 'about' ] }
                ];
                config.removeButtons = 'Save,NewPage,ExportPdf,Preview,Print,Templates,About,Flash,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Scayt,Find,Replace,BidiLtr,BidiRtl,Language,Maximize';
                config.colorButton_colors = '201d4d,0F0F63,0000b3,F6AD1E,58595b,efefef,f6f6f6,fff';
                config.extraAllowedContent = 'iframe[*];';
                config.autoParagraph = false;

                if (self.get('upload_url')) {
                    config.filebrowserUploadUrl = self.get('upload_url') ;
                }
                if (self.get('browse_url')) {
                    config.filebrowserBrowseUrl = self.get('browse_url');   
                }
                return config;
            };
            let editor = CKEDITOR.inline(el, make_config());
            editor.on('change', debounce(function() {
                    self.set('body', editor.getData());
                }, 200));
            self.set('editor', editor);
            window.editor = editor;
        },
        onunrender: function() {
        }
    });
}


Ractive.components.menueditor = Ractive.extend({
    isolated: true,
    template: `
        <div class="accordion" id="[[guid]]">
            [[#each items]]
            <div class="accordion-item" [[#unless ~/expanded]]as-sortable[[/unless]]>
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" on-click="expand" class-bg-danger="has_error" type="button" data-bs-toggle="collapse" data-bs-target="#[[guid]]-[[@index]]" aria-expanded="false" aria-controls="[[guid]]-[[@index]]">
                        [[#unless ~/expanded]]<i class="fa fa-sort me-2"></i>[[/unless]][[name]]
                    </button>
                </h2>
                <div id="[[guid]]-[[@index]]" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#[[guid]]">
                    <div class="accordion-body">
                        <div class="mb-3">
                            <input placeholder="name" value="[[name]]" class="form-control mb-3[[#if name_error]] is-invalid[[/if]]">
                            [[#if name_error]]
                            <div class="invalid-feedback">
                                [[name_error]]
                            </div>
                            [[/if]]
                            [[#if ~/has_subtext]]
                                <input placeholder="subtext" value="[[subtext]]" class="form-control mb-3[[#if subtext_error]] is-invalid[[/if]]">
                                [[#if subtext_error]]
                                <div class="invalid-feedback">
                                    [[subtext_error]]
                                </div>
                                [[/if]]
                            [[/if]]
                            <input placeholder="link" value="[[link]]" class="form-control [[#if link_error]] is-invalid[[/if]]">
                            [[#if link_error]]
                            <div class="invalid-feedback">
                                [[link_error]]
                            </div>
                            [[/if]]
                        </div>
                        <div class="mb-3">
                            <button on-click="remove" type="button" class="btn btn-sm btn-danger">Remove</button>
                        </div>
                    </div>
                </div>
            </div>
            [[/each]]
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#[[guid]]-new" aria-expanded="false" aria-controls="[[guid]]-new">
                        [[#if new_item.name]][[new_item.name]][[else]]Add another item[[/if]]
                    </button>
                </h2>
                <div id="[[guid]]-new" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#[[guid]]">
                    <div class="accordion-body">
                        <input placeholder="name" value="[[new_item.name]]" class="form-control mb-3">
                        [[#if ~/has_subtext]]
                            <input placeholder="subtext" value="[[new_item.subtext]]" class="form-control mb-3">
                        [[/if]]
                        <input placeholder="link" value="[[new_item.link]]" class="form-control mb-3">
                        <button type="button" class="btn btn-sm btn-primary" on-click="append">Add new</button>
                    </div>
                </div>
            </div>
        </div>
    `,
    css: `
        .accordion .droptarget {
            border: 1px dashed rgb(200,200,100) !important;
            background: rgb(240,240,200) !important;
            background-image: none !important;
            color: transparent;
        }
    `,
    data: function(){ 
        return {guid: 'me-' + guid(), items: [], new_item: {name: '', link: '', subtext: ''}, has_subtext: false};
    },
    delimiters: [ '[[', ']]' ],
    tripleDelimiters: [ '[[[', ']]]' ],
    onrender: function() {
        let self = this;
        self.on('expand', function(context) {
            const kp = context.resolve();
            if (kp == self.get('expanded')) {
                self.set('expanded', null);
            } else {
                for(let node of self.findAll('div.accordion-item')) {
                    node.draggable = false;
                }
                self.set('expanded', kp);
            }
        });
        self.on('append', function(context) {
            self.push('items', self.get('new_item'));
            self.set('new_item', {name: '', link: '', subtext: ''});
        });
        self.on('remove', function(context) {
            self.pop_array_path(context.resolve());
        });
    },
    onunrender: function() {
    }
});

Ractive.components.paged = Ractive.extend({
    template: `[[yield]]
               <div class="canary"></div>
              `,
    data: function(){ return {
        enabled: false
    };},
    delimiters: [ '[[', ']]' ],
    tripleDelimiters: [ '[[[', ']]]' ],
    oninit: function() {
        let self = this;
    },
    onrender: function() {
        let self = this;
        self.intersectionobs = new IntersectionObserver(
                                function(entries) {
                                    let entry = entries[0];
                                    if (entry.intersectionRatio > 0) {
                                        self.fire('end_of_page');
                                    }
                                }, 
                                {rootMargin: '50px 0px'});
        self.observe('enabled', function ( newValue, oldValue, keypath ) {
            if (!newValue && oldValue) {
                self.intersectionobs.disconnect();
            } else if (newValue && !oldValue) {
                self.intersectionobs.observe(self.find('.canary'));
            }
        });
    },
    onunrender: function() {
       this.intersectionobs.disconnect()
    }
});


Ractive.components.glossary = Ractive.extend({
    isolated: true,
    template: `
        <div class="container position-relative mt-5">
            <div class="d-flex flex-row justify-content-between align-items-center">
                <div class="input-group w-50">
                    <input type="search" class="form-control" id="search-input" placeholder="Search terms..." aria-label="Search terms..." value="[[query.word]]" lazy="400">
                    <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">[[query.language]]</button>
                    <ul class="dropdown-menu">
                        [[#each languages]]
                        <li><a class="dropdown-item" on-click="@this.set('query.language', this)">[[this]]</a></li>
                        [[/each]]
                    </ul>
                </div>
            </div>
        </div>

        <div class="container py-5">
            <div class="search-results mb-5">
                <paged enabled="[[pager_enabled]]">
                    <div class="row">
                        [[#each items]]
                        <div class="col-12 col-md-3"><a class="m-3 d-inline-block" on-click="show_table">[[word]]</a></div>
                        [[/each]]
                    </div>
                </paged>
                [[#if loading]] 
                    <div class="text-center">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div> 
                [[/if]]
            </div>
        </div>

        <div class="modal fade" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">[[modal_content.title]]</h5>
                            <button type="button" class="btn-close" on-click="close_table" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            [[#if modal_content.loading]]
                                <div class="text-center">
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div> 
                            [[else]]
                                <table id="modal_table" class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th>Language</th>
                                            <th>Translation</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        [[#each modal_content.items]]
                                        <tr>
                                            <td>[[language]]</td>
                                            <td>[[word]]</td>
                                        </tr>
                                        [[/each]]
                                    </tbody>
                                </table>
                            [[/if]]
                        </div>
                        [[#unless modal_content.loading]]
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" on-click="close_table">Close</button>
                          <button type="button" on-click="copy_table" class="btn btn-primary"><i class="bi bi-files"></i> Copy</button>
                        </div>
                        [[/unless]]
                    </div>
                </div>
        </div>
    `,
    data: function(){ return { 
        items: [],
        my_modal: null,
        modal_content: {title:'Loading...', items: [], loading: true},
        languages: [],
        query: {
            word: '',
            language: 'English'
        },
        load_more: true,
        search_url: '',
        table_url: '',
    };},
    delimiters: [ '[[', ']]' ],
    tripleDelimiters: [ '[[[', ']]]' ],
    oncomplete: function() {
        var self = this;
        self.set('my_modal', new bootstrap.Modal(self.find('.modal'), {}));
        self.on('paged.end_of_page', function(context) {
            self.set('loading', true);
            self.fire('fetch');
        });
        self.on('fetch', function(context) {
            self.set('pager_enabled', false);
            let data = self.get('query');
            self.add('page');
            data.page = self.get('page');
            if(self.get('load_more') || (self.get('items.length') == 0)) {
                $.ajax({
                    type: "PUT",
                    dataType: "json",
                    contentType: "application/json; charset=UTF-8",
                    url: self.get('search_url'),
                    data: JSON.stringify(data),
                    success: function (data) {
                        if(data.items.length > 0) {
                            self.set('items', self.get('items').concat(data.items));
                            self.set('pager_enabled', true);
                        }                    
                    }
                }).always(function() {
                    self.set('loading', false);
                });
            } else {
                self.set('loading', false);
                self.set('pager_enabled', false);
            }
        });
        self.observe('query.*', function(nv, ov, kp) {
            self.set({'page': 0, 'items': [], 'loading': true});
            self.fire('fetch');    
        });
        self.on('close_table', function(context) {
            self.get('my_modal').hide();
        });
        self.on('show_table', function(context) {
            self.set('modal_content', {title:context.get('word'), items: [], loading: true});
            self.get('my_modal').show();
            $.ajax({
                type: "GET",
                dataType: "json",
                contentType: "application/json; charset=UTF-8",
                url: self.get('table_url') + '/' + context.get('glossary'),
                success: function (data) {
                    self.set('modal_content.items', data.items);
                }
            }).always(function() {
                self.set('modal_content.loading', false);
            });
        });
        self.on('modal', function(context) {
            self.link(context.resolve(), 'modal_item');
            self.get('my_modal').show();
        });
        self.on('copy_table', function(context) {
            var r = document.createRange();
            r.selectNode(document.getElementById('modal_table'));
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(r);
            navigator.clipboard.writeText(window.getSelection());
            window.getSelection().removeAllRanges();
            
        });
    },
    onunrender: function() {
    }
});


Ractive.components.pageloader = Ractive.extend({
    template: `
               <div id="[[id]]"></div>
              `,
    data: function(){ 
        return {
            'url': '',
            'id': guid().replaceAll('-', '')
        };
    },
    delimiters: [ '[[', ']]' ],
    tripleDelimiters: [ '[[[', ']]]' ],
    oncomplete: function() {
        let self = this;
        self.observe('url', function(nv, ov, kp) {
            if (nv.length > 0) {
                if (nv.startsWith('/')) {
                    nv = window.location.protocol + '//' + window.location.hostname + nv;
                }
                let parsed = new URL(nv);
                let component_url = parsed.pathname
                if (!component_url.endsWith('.load')) {
                    component_url += '.load';
                }
                component_url += parsed.search;
                $.web2py.component(component_url, self.get('id'));    
            }
        });
    }
});
